<template>
  <v-dialog
    v-model="redactModal"
    width="80%"
    @click:outside="$emit('update:redactModal', false)"
  >
    <v-card v-if="redactingBanner" class="modal" :dark="$dark.get()">
      <v-card-title>
        Редактирование баннера {{ redactingBanner.bannerName }}
      </v-card-title>
      <v-card-text class="modal__text">
        <loader v-if="loading"></loader>
        <!-- не нужен для баннеров категорий -->
        <v-select
          v-if="!isCategoryBanners"
          :value="
            redactingBanner.bannerType ? redactingBanner.bannerType.id : 0
          "
          class="modal__select"
          label="Тип баннера"
          :items="types"
          :rules="[$validate.required]"
          required
          item-text="typeName"
          item-value="typeId"
          @change="addType($event)"
        ></v-select>
        <div v-if="redactingBanner.bannerType">
          <!-- для баннеров с категорией -->
          <v-autocomplete
            v-if="redactingBanner.bannerType.id !== 3"
            v-show="!mainCategory"
            ref="category"
            :value="redactingBanner.categoryId"
            :items="categoryes"
            item-text="name"
            item-value="id"
            no-data-text="Ничего не найдено"
            class="mt-3"
            label="Категория баннера"
            clearable
            @change="searchCategory = $event"
            @click:clear="searchCategory = null"
          />
          <!-- для баннеров с продуктами -->
          <v-text-field
            v-else
            :value="redactingBanner.productId"
            :rules="[$validate.required]"
            label="Id продукта"
            type="number"
            required
            clearable
            @change="productId = $event"
          ></v-text-field>
          <!-- присваивание филиала -->
          <v-autocomplete
            v-if="redactingBanner.bannerType.id !== 3 && !isCategoryBanners"
            v-show="!mainCategory"
            ref="department"
            :value="redactingBanner.storeOpeningDepartment"
            :items="DEPARTMENT.cityDepartment"
            item-text="name"
            item-value="id"
            no-data-text="Ничего не найдено"
            class="mt-3"
            label="Филиал для перехода"
            clearable
            @change="departmentId = $event"
            @click:clear="departmentId = null"
          />
          <!-- для баннеров с категорией, категория для перехода-->
          <v-autocomplete
            v-if="isCategoryBanners"
            :value="redactingBanner.linkedCategoryId"
            :items="categoryes"
            item-text="name"
            item-value="id"
            no-data-text="Ничего не найдено"
            class="mt-3"
            label="Категория для перехода"
            clearable
            @change="clickedCategory = $event"
            @click:clear="clickedCategory = null"
          />
          <v-checkbox
            v-if="isCategoryBanners"
            v-model="mainCategory"
            label="Сделать баннер корневым"
            hide-details
          ></v-checkbox>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn @click="$emit('update:redactModal', false)">Закрыть</v-btn>
        <div v-if="redactingBanner.bannerType" class="ma-2">
          <v-btn
            v-if="redactingBanner.bannerType.id !== 3"
            color="#5d4bd0"
            dark
            @click="addCategoryOrDepartment()"
            >Изменить</v-btn
          >
          <v-btn v-else color="#5d4bd0" dark @click="addProduct()"
            >Добавить продукт</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import loader from "@/components/Loader";
import ShowMessage from "@/Functions/message";
import api from "@/api";

export default {
  name: "RedactBannerDialog",
  components: { loader },
  props: {
    redactModal: {
      type: Boolean,
      default: false,
    },
    redactingBanner: {
      type: Object,
      default() {
        return {};
      },
    },
    categoryes: {
      type: Array,
      default() {
        return [];
      },
    },
    isCategoryBanners: {
      type: Boolean,
      default: false,
    },
    chosenDepartment: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      searchCategory: null,
      clickedCategory: null,
      mainCategory: false,
      productId: null,
      departmentId: null, // для привязки департамента к баннеру, микромагазины
      types: [
        { typeName: "С категорией", typeId: 2 },
        { typeName: "С продуктом", typeId: 3 },
        { typeName: "Слайдер", typeId: 1 },
        { typeName: "4 баннера", typeId: 4 },
        { typeName: "4 верхних", typeId: 6 },
        { typeName: "Двойные", typeId: 7 },
        { typeName: "Узкий", typeId: 8 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      DEPARTMENT: "Department/STATE",
    }),
  },
  watch: {
    redactModal(val) {
      if (this.redactingBanner.categoryId === 0) {
        this.mainCategory = true;
      }
      if (!val) {
        this.searchCategory = null;
        this.productId = null;
        this.mainCategory = false;
      }
    },
    mainCategory(val) {
      if (val) {
        this.searchCategory = 0;
      }
    },
    departmentId(val) {
      if (val) {
        this.$refs.category.reset();
        this.searchCategory = null;
      }
    },
    searchCategory(val) {
      if (val) {
        this.$refs.department.reset();
        this.departmentId = null;
      }
    },
  },
  methods: {
    async addCategoryOrDepartment() {
      this.loading = true;
      let payload = {
        bannerId: this.redactingBanner.bannerId,
        id: this.redactingBanner.bannerId,
        categoryId:
          this.searchCategory !== null
            ? this.searchCategory
            : this.redactingBanner.categoryId,
      };
      let response;
      if (this.isCategoryBanners) {
        payload.categoryBannerId = this.redactingBanner.categoryBannerId;
        payload.cityId = this.chosenDepartment * -1;
        payload.linkedCategoryId =
          this.clickedCategory || this.redactingBanner.linkedCategoryId;
        response = await api.Banner.bindBannerWithCategory(payload);
      } else {
        payload.storeOpeningDepartment = this.departmentId;
        if (this.departmentId) {
          payload.categoryId = null;
        }
        response = await api.Banner.addCategoryToBanner(payload);
      }
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("К баннеру добавлена категория", true);
        this.$emit("updateBanners");
        this.$emit("update:redactModal", false);
      }
      this.loading = false;
    },
    async addProduct() {
      if (!this.productId) {
        ShowMessage("Добавьте или измените ID продукта");
        return;
      }
      this.loading = true;
      let payload = {
        bannerId: this.redactingBanner.bannerId,
        productId: this.productId,
      };
      let response = await api.Banner.addProductToBanner(payload);
      if (response.type === "error") {
        ShowMessage(response.text);
      } else {
        ShowMessage("К баннеру добавлен продукт", true);
        this.$emit("updateBanners");
        this.$emit("update:redactModal", false);
      }
      this.loading = false;
    },
    async addType(typeId) {
      this.loading = true;
      let payload = {
        bannerId: this.redactingBanner.bannerId,
        typeId,
      };
      let response = await api.Banner.addTypeToBanner(payload);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("К баннеру добавлен тип", true);
        this.$emit("updateBanners");
        this.$emit("update:redactModal", false);
      }
      this.loading = false;
    },
  },
};
</script>
